$('.upload').click(uploadButtonPress);

function uploadButtonPress(){
        $('#product_id').val($(this).attr('product_id'));
        $('#form_upload_input').click();
}

$('#form_upload_input').change(function(){
    //$('#form_upload_artwork').submit();

    /**
     * Upload using FormData class
     */

    formData = new FormData( $('#form_upload_artwork')[0] );

    $('#uploadAnimation').css( 'display', 'block' );

    $.ajax({
        xhr: function() {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function(evt) {
                if (evt.lengthComputable) {
                    var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
                    if( percentComplete == '100' ){
                        $('#currentUpload').html('Please wait, varifying upload');
                    } else {
                        $('#currentUpload').html(percentComplete + ' % Uploaded');
                    }
                }
           }, false);
           return xhr;
        },
        url: '/upload',
        data: formData,
        type: 'POST',
        contentType: false, 
        processData: false, // NEEDED, DON'T OMIT THIS
        complete: function(d){
            alert( 'Your files have been uploaded successfully' )
            $('#uploadAnimation').css( 'display', 'none' );
            $('#products').html(d.responseText);

            /**
             * Reset upload and delete file buttons
             */
            $('.upload').click(uploadButtonPress); //reset the upload buttons

            $('.delete_file').submit(function(e){
                e.preventDefault();
                let formData = new FormData(this);
                let fileName = formData.get('file');
                if(confirm('Delete uploaded artwork file ' + fileName)){
                    $.ajax({
                        url: '/upload',
                        data: formData,
                        type: 'POST',
                        contentType: false, 
                        processData: false, // NEEDED, DON'T OMIT THIS
                        complete: function(d){
                            alert( 'File deleted' );
                            $('#uploadAnimation').css( 'display', 'none' );
                            $('#products').html(d.responseText);
                
                            /**
                             * Reset upload and delete file buttons
                             */
                            $('.upload').click(uploadButtonPress); //reset the upload buttons
                
                            $('.delete_file').submit(function(e){
                                e.preventDefault();
                                let formData = new FormData(this);
                                let fileName = formData.get('file');
                                if(confirm('Delete uploaded artwork file ' + fileName)){
                                    this.submit();
                                }
                            });
                        },
                        error:function(e){
                            console.log(e);
                            $('#uploadAnimation').css( 'display', 'none' );
                        }
                    });
                }
            });
        },
        error:function(e){
            console.log(e);
            $('#uploadAnimation').css( 'display', 'none' );
        }
    });
});

$('.delete_file').submit(function(e){
    e.preventDefault();
    let formData = new FormData(this);
    let fileName = formData.get('file');
    if(confirm('Delete uploaded artwork file ' + fileName)){
        $.ajax({
            url: '/upload',
            data: formData,
            type: 'POST',
            contentType: false, 
            processData: false, // NEEDED, DON'T OMIT THIS
            complete: function(d){
                alert( 'File deleted' );
                $('#uploadAnimation').css( 'display', 'none' );
                $('#products').html(d.responseText);
    
                /**
                 * Reset upload and delete file buttons
                 */
                $('.upload').click(uploadButtonPress); //reset the upload buttons
    
                $('.delete_file').submit(function(e){
                    e.preventDefault();
                    let formData = new FormData(this);
                    let fileName = formData.get('file');
                    if(confirm('Delete uploaded artwork file ' + fileName)){
                        this.submit();
                    }
                });
            },
            error:function(e){
                console.log(e);
                $('#uploadAnimation').css( 'display', 'none' );
            }
        });
    }
});

$(document).ready(function(){
    //setBoxSize();
    //$( window ).resize( setBoxSize );
    
});

function setBoxSize(){
    /**
     * Style cart so all products are the same height
     */
    $('.product').css('height', 'auto'); //reset size for smaller viewports
    var maxHeight = -1;
    $('.product').each(function() {
        if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
        }
    });
    $('.product').height(maxHeight);
}